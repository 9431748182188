.app-content {
  display: flex;
}
.main-content {
  flex: 1;
  height: calc(100vh - 119px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main-content::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  .sidebar {
    display: none;
  }
}
